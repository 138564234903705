@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.breadcrumbs {

	&__section {
		position: relative;

		.btn {
			&:focus {
				color: inherit;
			}
		}

		h1, h2, h3, h4, h5 {
			position: relative;
			z-index: 5;
		}

		.breadcrumb {
			margin: 0;
			padding: 0;
			position: relative;
			z-index: 10;
			display: none;
			background-color: transparent;

			@media #{$sm-min}{
				display: inline-flex;
				justify-content: space-between;
				align-content: center;
			}

			li {
				display: block;
				position: relative;
			}
		}

		&-sunshine {
			position: relative;

			h2 {
				text-transform: uppercase;
			}

			.breadcrumb-bg {
				box-shadow: 0 0 20px rgba(0, 0, 0, .3) inset;
				z-index: 1;
			}

			& * {
				position: relative;
				z-index: 5;
			}

			h2 {
				color: $white;
				letter-spacing: -0.5px;
			}

			.full__size-absolute{
				position: absolute;
			}

			.breadcrumbs__wrapper {
				position: absolute;
				bottom: 0;
				left: 0;
				height: auto;
				width: 100%;
				transform: translate(0, 50%);

				.breadcrumb {
					position: relative;
					border-radius: 13px;
					margin: 0;
					height: auto;
					padding: 11px 15px 61px 13px;
          overflow: hidden;
					display: flex;
					justify-content: flex-start;

          &_squared {
            border-radius: 0;
          }

					@media #{$sm-min} {
						padding-bottom: 11px;
					}

					li {
						display: flex;
						float: left;
						color: #fff;
						padding: 0 6px;
						line-height: 32px;
            vertical-align: middle;

						a {
							color: $white;
							display: inline-block;
						}

						> i {
							line-height: inherit;
							margin-left: 16px;
							margin-right: 2px;
						}

						&.breadcrumb__icon {
							padding: 0 10px 0 0;

							a {
								position: relative;
								width: 32px;
								height: 32px;
								border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
								background: var(--white);
								color: var(--brk-base-2);
                box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
                .brk-bordered-theme & {
                  border-radius: var(--b-radius);
                }

								&:after {
									display: none;
								}
							}
						}
					}
				}
      }

      &_white{
        .breadcrumbs__wrapper {
          .breadcrumb {
            li {
              color: #ffffff;
            }
          }
        }
      }
		}

		&-angle {
			position: relative;
			overflow: hidden;

			.breadcrumbs__wrapper {
				display: flex;
				justify-content: space-between;

				@media (min-width: 768px) {
					flex-direction: row-reverse;
				}
			}

			.breadcrumb__shape {
				position: absolute;
				top: 0;
				height: 100%;
				right: -12.5%;
				width: 50vw;
				z-index: 5;
				transform: skewX(-30deg);
				display: none;

				@media #{$sm-min} {
					display: block;
				}
      }

      .breadcrumb-bg {
				opacity: .9;
				z-index: 1;
			}
      
      &-right{
        .breadcrumb__shape{
          transform: none;
          background-image: linear-gradient(to top, rgba(var(--brand-primary-rgb), 0.9) 0%, rgba(var(--secondary-rgb), 0.9) 100%);
          width: 33%;
          right: 0;
        }
        .breadcrumb-bg{
          background-image: linear-gradient(to top, rgba(0, 10, 26, 0.94) 0%, rgba(69, 71, 76, 0.94) 100%);
          width: 67%;
          opacity: 1;
        }
      }

			h2 {
				color: $white;
				position: relative;
				z-index: 10;
				text-align: center;
				width: 100%;
				letter-spacing: 1px;

				@media #{$sm-min} {
					text-align: right;
					width: auto;
				}
			}

			.breadcrumb {
				margin: 0;
				padding: 7px 0;
				position: relative;
				z-index: 10;
				background: transparent;

				li {
					display: block;
					float: left;
					padding: 0 42px 0 51px;
					line-height: 32px;
					vertical-align: middle;
					font-size: 14px;
					position: relative;
					color: $white;

					&:first-child {

						.separator {
							&:before {
								display: none;
							}
						}
					}

					a {
						font-size: 14px;
						color: $white;
						letter-spacing: 0.4px;
					}

					.separator {
						color: inherit;
						height: 12px;
						width: 12px;
						position: absolute;
						display: block;
						border-radius: 50%;
						border: 2px solid;
						left: 0;
						top: 50%;
						transform: translate(0, -50%);
						background: radial-gradient($white, $white 1.7px, transparent 1.7px, transparent) center;

						&:before,
						&:after {
							content: '';
							position: absolute;
							top: 50%;
							margin-top: -1px;
							width: 30px;
							height: 0;
							border: 1px solid;
						}

						&:before {
							right: 100%;
							margin-right: 2px;
						}

						&:after {
							left: 100%;
							margin-left: 2px;
						}
					}

					&.active {
						opacity: .4;

						.separator {
							background: none;
						}
					}
				}
			}
		}

		&-trend {
			overflow: hidden;

			h2, h5 {
				color: $white;
				position: relative;
				z-index: 5;
			}

			h2{
        letter-spacing: -0.4px;
        top: -6px;
			}

			h5 {
				color: #bad3ff;
			}

			.breadcrumbs__wrapper {
				display: flex;
				justify-content: space-between;
			}

			.breadcrumb-bg {
				opacity: .95;
				z-index: 1;
			}

			.breadcrumb {
				padding: 20px 0;
				margin: 0;
				background: transparent;

				li {
					display: block;
					float: left;
					position: relative;
					z-index: 10;
					color: #b9efff;
					padding: 0 13px;
					text-transform: uppercase;

					&:after {
						content: '/';
						color: $white;
						position: absolute;
						display: block;
						top: 50%;
						right: -3px;
						transform: translate(0, -50%);
					}

					&.active {
						padding-right: 10px;

						&:after {
							display: none;
						}
					}

					a {
						color: $white;

						&:hover {
							color: #b9efff;
						}
					}
				}
			}
		}

		&-grayscale {
			overflow: hidden;

			.breadcrumbs__wrapper {
				display: flex;
				justify-content: space-between;
			}

			.highlight-trend {
        background: var(--brand-primary);
        .brk-bordered-theme & {
          border-radius: var(--b-radius);
        }
			}

			.breadcrumb-bg {
				opacity: .88;
				z-index: 1;
			}

			.breadcrumb {
				padding: 4px 25px;
				background: $white;
				border-radius: 15px;
				box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
        margin: 24px 0;
        .brk-bordered-theme & {
          border-radius: var(--b-radius);
        }

				li {
					color: $black;
					padding: 0 5px;

					a {
						position: relative;
						color: var(--brand-primary);

						i {
							line-height: 23px;
							margin-left: 10px;
						}
					}
				}
			}
		}

		&-dotted {
			overflow: hidden;

			.breadcrumbs__wrapper {
				display: flex;
				justify-content: space-between;
			}

			.breadcrumb-bg {
				opacity: .7;
			}

			h2 {
				color: $white;
				text-transform: uppercase;
			}

			h5 {
				margin-top: -5px;
				color: var(--brk-base-1);
			}

			.breadcrumb {
				background: transparent;

				li {
					padding: 0 10px;
					color: var(--brk-base-1);

					&:after {
						content: '/';
						color: $white;
						position: absolute;
						display: block;
						top: 50%;
						right: -3px;
						transform: translate(0, -50%);
					}

					&.active {
						padding-right: 10px;

						&:after {
							display: none;
						}
					}

					a {
						color: $white;

						&:hover {
							color: #b9efff;
						}
					}
				}
			}
		}

		&-line {
			overflow: hidden;
			letter-spacing: 0.4px;

			h2, h4 {
				text-transform: none;
			}

			h4 {
				margin: 0;
				letter-spacing: -0.4px;

				& > .before{
					top: -19px;
					bottom: -12px;
				}
      }

			.breadcrumbs__wrapper {
				display: flex;
				justify-content: space-between;
			}
      
      .highlight-underline{
        line-height: normal;
      }

			.breadcrumb {
				background: transparent;
				padding: 41px 0;
				text-transform: uppercase;

				li {
					color: rgba(#272727, .6);
					padding: 0 21px;

					&:after {
						content: "\f054";
						position: absolute;
						display: block;
						top: 50%;
						right: -3px;
						font-family: "Font Awesome\ 5 Free";
            transform: translate(0, -50%);
            font-weight: 600;
					}

					&.active {
						padding-right: 10px;

						&:after {
							display: none;
						}
					}

					a {
						color: var(--brk-secondary-3);

						&:hover {
							//color: #b9efff;
						}
					}
				}
			}

			.highlight-underline > .before {
				background: var(--brand-primary);
			}
		}

		&-dark {
			h2 {
				color: $white;
			}

			.breadcrumb-bg {
				opacity: .7;
			}

			.breadcrumbs__wrapper {
				position: absolute;
				bottom: 0;
				left: 0;
				height: auto;
				width: 100%;
				transform: translate(0, 50%);
				z-index: 10;

				.container {
					position: relative;
				}

				.breadcrumb {
					position: relative;
					margin: 0;
					background: $white;
					height: auto;
					padding: 0;
					border-radius: 0;
					overflow: hidden;
					text-transform: uppercase;
					box-shadow: 0 5px 16px rgba(0, 0, 0, .12);

					li {
						display: block;
						float: left;
						color: #d6d6d6;
						line-height: 30px;
						vertical-align: middle;
						padding: 8px;

						@media (max-width: 767px) {
							line-height: 20px;
						}

						a {
							color: #272727;
							position: relative;
							display: block;

							i {
								line-height: 29px;
								margin-left: 10px;

								@media (max-width: 767px) {
									line-height: 20px;
								}
							}
						}

						&.breadcrumb__icon {
							padding: 0;

							a {
								position: relative;
								width: 103px;
								height: 90px;
								display: block;
								background: $white;
								line-height: 90px;
								vertical-align: middle;
								text-align: center;
								padding-right: 20px;
								font-size: 16px;
								text-indent: 10px;
								margin-right: 35px;
								color: var(--brand-primary);

								@media (max-width: 767px) {
									height: 70px;
									width: 45px;
									line-height: 70px;
									padding-right: 0;
									margin-right: 0;
									text-indent: 0;
								}

								&:after {
									content: '';
									display: block;
									width: 90px;
									height: 90px;
									position: absolute;
									transform: rotate(45deg) skewX(15deg) skewY(15deg);
									transform-origin: center;
									box-shadow: 2px -2px 6px rgba(0, 0, 0, .08);
									padding: 0;
									margin: 0;
									top: 0;
                  right: 0;

									@media (max-width: 767px) {
										display: none;
									}

                  .brk-bordered-theme & {
                    border-radius: var(--b-radius);
                  }
								}

								i {
									position: absolute;
									top: 50%;
									left: calc(50% - 5px);
									transform: translate(-50%, -50%);
								}
							}
						}
					}
				}
			}

			.dropdown {
				position: absolute;
				top: 0;
				right: 15px;
				width: auto;
				height: 100%;
				z-index: 20;

				&.show {
					.dropdown-toggle {
						i {
							transform: rotate(-180deg);
						}
					}

					.dropdown-menu {
						display: block;
					}
				}

				.dropdown-toggle {
					margin: 0;
					padding: 33px 30px;
					border: none;
					color: #272727;
					min-width: 190px;
					text-align: center;

					@media (max-width: 767px) {
						padding: 23px 15px;
						min-width: 160px;
					}

					&:after {
						display: none;
					}

					i {
						color: var(--brand-primary);
						margin-left: 15px;
						transition: transform ease .3s;
					}
				}

				.dropdown-menu {
					width: 100%;
					max-width: 100%;
					min-width: 190px;
					border-radius: 0;
					margin: 0;
					padding: 0;
					border-top: none;
					border-left: none;
					border-right: none;
					border-bottom-width: 2px;
					border-bottom-style: solid;
					box-shadow: 0 2px 16px rgba(0, 0, 0, .15);

					@media (max-width: 767px) {
						min-width: 160px;
					}

					li {
						border-top: 1px solid #ececec;
						padding: 0;
						text-align: left;

						&:first-child {
							border: none;
						}

						a {
							color: rgba(#272727, .3);
							display: block;
							padding: 20px 30px;
							text-align: center;
							text-transform: uppercase;

							@media (max-width: 767px) {
								padding: 10px;
							}

							&:hover {
								color: #272727;
							}
						}
					}
				}
			}

			.breadcrumb {
				display: flex;
				justify-content: flex-start;
				align-items: center;
			}
		}

		&-squared {
			overflow: hidden;

			.breadcrumb-bg {
				opacity: .7;
			}

			h2 {
				color: $white;
				text-transform: uppercase;

				span{
					letter-spacing: -0.9px;
				}
      }
      .brk-bordered-theme & {
        .categories-list{
          border-top-left-radius: var(--b-radius);
        }
        @media screen and (max-width: 575px){
          .categories-list{
            border-radius: var(--b-radius);
            overflow: hidden;
          }
        }
      }

			.breadcrumb {
				background: transparent;
				margin-top: 20px;

				li {
					display: block;
					float: left;
					color: #a4a4a4;
					line-height: 30px;
					vertical-align: middle;
					padding: 0 8px;
					text-transform: uppercase;

					&:first-child {
						padding-left: 0;
					}

					a {
						color: $white;
						position: relative;
						padding-right: 0;
						padding-left: 0;

					}
					.breadcrumb__icon{
            margin-left: 13px;
            vertical-align: baseline;
					}
				}
			}

			.dropdown.horizontal {

				@media #{$xs-max} {
					width: 100%;
					bottom: auto;
					position: relative;
					right: auto;
				}

				.dropdown-toggle {
					float: left;
					margin: 0;
					/*padding: 37px 0 19px 40px;*/
					padding: 37px 5px 19px 55px;
					border: none;
					background: $white;
					z-index: 20;
					display: flex;
          align-items: center;
          .brk-bordered-theme & {
            border-top-left-radius: var(--b-radius);
          }

					@media #{$xs-max} {
						width: 100%;
						border-radius: 0;
						float: none;
						padding: 25px;

					}
					.categories__button__icon{
						transform: rotate(0);
						font-size: 24px;
						transition: transform ease .3s;
						margin-left: 20px;

					}
					&:after {
						content:'';
						display: none;
					}
				}

				.dropdown-menu {
					z-index: 1;
					display: block;
					float: left;
					position: relative !important;
					top: auto !important;
					left: auto !important;
					box-shadow: none;
					border: none;
					padding: 0;
					margin: 0;
					width: auto;
					max-width: 0;
					min-width: 0;
					border-radius: 0;
					font-size: 0;
					max-height: 80px;
					height: 100%;
					transition: max-width 1s ease 0s;
					white-space: nowrap;
					transform: none !important;

					@media #{$xs-max} {
						max-width: 100%;
						width: 100%;
						position: absolute !important;
						opacity: 0;
						left: 0 !important;
						right: 0 !important;
						transition: all ease .3s;
						max-height: 0;
					}

					li {
						display: inline-block;
						font-size: 12px;

						@media #{$xs-max} {
							display: block;
						}

						a {
							display: block;
							white-space: nowrap;
							padding: 37px 11px 19px 45px;
							color: rgba(#272727, .3);
							&.is-active{
								color: #272727;
							}
							&.line-through{
								text-decoration: line-through;
							}
							&:hover {
								color: #272727;
							}
						}
					}
				}

				&.show {
					.dropdown-toggle {

						.categories__button__icon {
							transform: rotate(90deg);

							@media #{$xs-max}{
								transform: rotate(-90deg);
							}
						}
					}

					.dropdown-menu {
						max-width: 50vw;
						max-height: 30vh;
						white-space: normal;
						transition: max-width 1s ease 0s, max-height 1s ease 1s;

						@media #{$xs-max} {
							transition-delay: 0s;
							position: relative !important;
							max-width: 100%;
							max-height: 1000px;
							top: 80px;
							opacity: 1;
						}
					}
				}
			}

			.categories {
				&-wrapper {
					position: absolute;
					bottom: 0;
					width: 100%;
					left: 0;

					@media #{$xs-max} {
						position: relative;
					}
				}

				&-list {
					background: $white;
					position: relative;
					float: right;
					color: #272727;
					z-index: 20;
					text-transform: uppercase;

					&:after {
						content: '';
						position: absolute;
						top: 0;
						background: $white;
						width: 500px;
						height: 100%;
						z-index: 130;
						left: calc(100%);

						@media #{$xs-max}{
							display: none;
						}
					}
				}
			}

			.breadcrumbs__wrapper .breadcrumb li.breadcrumb__icon a {
				color: var(--brand-primary);
			}
		}

		&-range {
			overflow: hidden;

			.breadcrumb-bg {
				opacity: .7;
			}

			h2 {
				color: $white;
				text-transform: uppercase;

				span{
					letter-spacing: -0.9px;
				}
      }
      
      .brk-bordered-theme & {
        .filter__container{
          border-top-left-radius: var(--b-radius);
        }
        @media screen and (max-width: 575px){
          .categories-list{
            border-top-right-radius: var(--b-radius);
            border-top-left-radius: var(--b-radius);
            overflow: hidden;
          }
          .filter__container{
            border-bottom-left-radius: var(--b-radius);
            border-bottom-right-radius: var(--b-radius);
          }
        }
      }

			.breadcrumb {
				background: transparent;
				margin-top: 20px;

				li {
					display: block;
					float: left;
					color: #a4a4a4;
					line-height: 30px;
					vertical-align: middle;
					padding: 0 8px;
					text-transform: uppercase;

					&:first-child {
						padding-left: 0;
					}

					a {
						color: $white;
						position: relative;
						padding-right: 0;
						padding-left: 0;

					}
					.breadcrumb__icon{
            margin-left: 13px;
            vertical-align: baseline;
					}
				}
			}

			.dropdown.horizontal {

				@media #{$xs-max} {
					width: 100%;
					bottom: auto;
					position: relative;
					right: auto;
				}

				.dropdown-toggle {
					float: left;
					margin: 0;
					padding: 37px 0 19px 25px;
					border: none;
					background: $white;
					z-index: 20;
					display: flex;
					align-items: center;

					@media #{$xs-max} {
						width: 100%;
						border-radius: 0;
						float: none;
					}

					.categories__button__icon{
						transform: rotate(0);
						font-size: 24px;
						transition: transform ease .3s;
						margin-left: 20px;

					}
					&:after {
						content:'';
						display: none;
					}
				}

				.dropdown-menu {
					z-index: 1;
					display: block;
					float: left;
					position: relative !important;
					top: auto !important;
					left: auto !important;

					box-shadow: none;
					border: none;
					padding: 0;
					margin: 0;
					width: auto;
					max-width: 0;
					min-width: 0;
					border-radius: 0;
					font-size: 0;
					max-height: 80px;
					height: 100%;
					transition: max-width 1s ease 0s;
					white-space: nowrap;
					transform: none !important;

					@media #{$xs-max} {
						max-width: 100%;
						width: 100%;
						position: absolute;
						opacity: 0;
						left: 0;
						right: 0;
						transition: all ease .3s;
                      max-height: 0;
					}

					li {
						display: inline-block;
						font-size: 12px;

						@media #{$xs-max} {
							display: block;
						}

						a {
							display: block;
							white-space: nowrap;
							padding: 37px 25px 19px 25px;
							color: rgba(#272727, .3);
							&.is-active{
								color: #272727;
							}
							&.line-through{
								text-decoration: line-through;
							}
							&:hover {
								color: #272727;
							}
						}
					}
				}

				&.show {
					.dropdown-toggle {
						.categories__button__icon {
							transform: rotate(90deg);

							@media #{$xs-max}{
								transform: rotate(-90deg);
							}
						}
					}

					.dropdown-menu {
						max-width: 50vw;
						max-height: 30vh;
						white-space: normal;
						transition: max-width 1s ease 0s, max-height 1s ease 1s;

						@media #{$xs-max} {
							max-width: 100%;
							top: 80px;
							opacity: 1;
                            transition-delay: 0s;
                            position: relative !important;
                            max-height: 1000px;
						}
					}
				}
			}

			.categories {
				&-wrapper {
					position: absolute;
					bottom: 0;
					width: 100%;
					left: 0;

					@media #{$xs-max} {
						position: relative;
					}
				}

				&-list {
					background: $white;
					position: relative;
					float: right;
					color: #272727;
					z-index: 20;
					text-transform: uppercase;

					&:after {
						content: '';
						position: absolute;
						top: 0;
						background: $white;
						width: 500px;
						height: 100%;
						z-index: 130;
						left: calc(100%);

						@media #{$xs-max}{
							display: none;
						}
					}
				}
			}

			.filter {
				width: 390px;
				flex-shrink: 0;
				float: right;
				display: flex;
				align-items: center;
				transition: width .5s ease-in-out , padding-right .5s ease-in-out ;
				overflow: hidden;
				padding-right: 18px;
				&__container {
					display: flex;
					padding-top: 36px;
					padding-bottom: 18px;
					height: 80px;
					align-items: center;
					background-color: #ffffff;
					float: right;
					.ui-slider {
						.ui-slider-handle {
							position: absolute;
							z-index: 50;
							-ms-touch-action: none;
							touch-action: none;
							width: 16px;
							height: 16px;
							box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
							border: 4px solid #ffffff;
							border-radius: 100%;
							top: -7px;
							outline: none;
							cursor: pointer;
							background: linear-gradient(
															7deg,
															var(--brk-secondary-3) 0%,
															var(--brk-secondary-6) 100%);
						}
						.ui-slider-range {
							position: absolute;
							z-index: 1;
							font-size: .7em;
							display: block;
							border: 0;
							height: 4px;
							background-color: var(--brk-secondary-3);
						}
					}
					.ui-widget-content {
						height: 10px;
						box-shadow: inset 1px 1px 7px rgba(0, 0, 0, 0.1);
						border: 3px solid #ffffff;
						background-color: #ffffff;
						position: relative;
						width: 230px;
						border-radius: 4px;
						margin-left: 10px;
					}
					.ui-widget-header {
						font-weight: bold;
					}
				}
				&__input {
					width: 45px;
					color: #000000;
					height: 24px;
					box-shadow: none;
					background: rgba(255, 255, 255, 0.12);
          padding: 0 3px;
          border-radius: 5px;
					border: 1px solid #d5d5d5;
					margin: 0 3px;
					text-align: center;

          .brk-bordered-theme & {
            border-radius: var(--b-radius);
          }
					&:last-child{
						margin-right: 9px;
					}

				}
				&__label {
					text-transform: uppercase;
					margin-right: 7px;
				}
				&__btn {
					padding: 0 38px 0 64px;
					border: none;
					background-color: transparent;
					text-transform: uppercase;
					cursor: pointer;
					outline: none;
					display: flex;
					align-items: center;
					&__icon {
						color: #272727;
						margin-left: 25px;
						margin-top: -3px;
						font-size: 22px;
						font-weight: 100;
						transform:rotate(90deg);
						transition: transform ease .3s;
						display: flex;
						align-items: center;


					}
					&.closed{
						.filter__btn__icon{
							transform: rotate(0);
						}
					}
				}
				&.closed{
					width: 0;
					padding-right: 0;
				}
				@media screen and(max-width: 767px) {
					width: 100% !important;
					padding-left: 20px !important;
					padding-right: 30px !important;
					flex-wrap: wrap;
					margin-top: 10px;
					justify-content: center;
					&__btn{
						display: none;
					}
					.ui-widget-content{
						flex-grow: 1;
						margin-top: 20px;
					}
					&__container{
						width: 100%;
						height: 100px;

					}
				}
			}
		}
    
    &-thin{
      padding-top: 140px;
      padding-bottom: 47px;
      overflow: hidden;

      .breadcrumb{
        li,
        a{
          font-size: inherit;
          color: inherit;
        }
        li{
          margin-right: 20px;
        }
        .icon{
          margin-left: 20px;
          opacity: .8;
        }
        .active{
          opacity: .8;
        }
      }
      .breadcrumbs__wrapper{
        padding-top: 40px;
        position: relative;
      }
      .breadcrumbs__title{
        position: relative;
        &::after{
          content: '';
          position: absolute;
          right: -15px;
          height: 100%;
          width: 1px;
          background-color: rgba(256,256,256,.1);
        }
      }
      .breadcrumbs__subtitle{
        left: 60px;
      }
    }
    
    &-simple{
      .breadcrumb{
        background: transparent;

        padding: 5px 0 15px 0;
        border-bottom: 1px solid rgba(256,256,256,.2);
        border-radius: 0;

        i {
          padding-left: 30px;
          padding-right: 12px;
        }
        li {
          display: flex;
          align-items: center;
          padding-left: 15px;
          padding-right: 15px;

          &.active{
            position: relative;
            &::after{
              content: '';

              position: absolute;
              bottom: -16px;
              left: 0;
              width: 100%;
              height: 2px;
              
              background-image: linear-gradient(to right, var(--brand-primary) 0%, var(--secondary) 100%);
            }
          }

					a {
						color: #ffffff;
					}
        }
      }
    }
	}

	&__wrapper {

		.form-horizontal {
			position: absolute;
			right: 15px;
			padding: 0 10px;
			bottom: 15px;
			left: 15px;
			z-index: 15;

			@media #{$sm-min} {
				transform: translate(0, -50%);
				top: 50%;
				bottom: auto;
				left: auto;
			}

			.form-group {
				margin: 0;
				position: relative;

				input {
					border-radius: 16px;
					border: 1px solid rgba(255, 255, 255, .4);
					box-shadow: none;
					background: rgba(255, 255, 255, .1);
					font-size: 14px;
					line-height: 24px;
					padding: 4px 45px 4px 20px;
					height: auto;
          width: 175px;
          color: #ffffff;

					@include placeholder(rgba(255, 255, 255, .8));

					@media (max-width: 575px) {
						width: 100%;
					}

          .brk-bordered-theme & {
            border-radius: var(--b-radius);
          }
				}

				button {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					width: 30px;
					color: $white;
					box-shadow: none;
					background: transparent;
					border: none;
					line-height: 34px;
					height: 34px;
					vertical-align: middle;
					margin: 0;
					padding: 0;
				}
			}
		}
	}

	&__bottom-btn{
		>.container{
			position: relative;
		}
		.breadcrumbs__btn{
			position: absolute;
			right: 0;
			bottom: 0;
			margin: 0;
      transform: translate(-50% , 50%);
      .brk-bordered-theme & {
        border-radius: var(--b-radius);
      }
		}

	}
}

